<template>
    <ul class="header-main-categories" :class="{ indeterminable: clearState }">
        <li v-for="{ id, label, url, slug } in tabsItems" :key="`tab-${id}`" class="tabs-item">
            <nuxt-link
                class="tabs-item-container"
                :class="{ selected: id === activeCategoryId && !clearState }"
                :to="url"
                :data-test-id="DEPARTMENT_LINK"
                @click.native="setNavigationContext(slug)"
            >
                <span class="tabs-label">{{ label }}</span>
            </nuxt-link>
        </li>
    </ul>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { DEPARTMENT_LINK } from '@types/AutomaticTestIDs';

const {
    mapGetters: mapNavigationGetters,
    mapActions: mapNavigationActions,
} = createNamespacedHelpers('navigation');

export default {
    name: 'HeaderMainCategoryMenu',

    props: {
        clearState: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return { DEPARTMENT_LINK };
    },

    computed: {
        ...mapNavigationGetters(['mainCategories']),

        activeCategoryId() {
            return this.mainCategories.find(({ isActive }) => isActive)?.id || '';
        },

        tabsItems() {
            return this.mainCategories.map(({ id, displayName, url, slug }) => ({
                id,
                label: displayName,
                url,
                slug,
            }));
        },
    },

    methods: {
        ...mapNavigationActions(['setNavigationContext']),
    },
};
</script>

<style lang="scss" scoped>
.header-main-categories {
    @apply h-ui-11;
    @apply flex m-ui-0 p-ui-0 gap-x-ui-6;
    @apply overflow-x-auto;
    @apply font-ui-body-s;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &.indeterminable {
        @apply text-ui-text-primary-default;
    }

    .tabs-item {
        @apply whitespace-nowrap;
    }

    .tabs-item-container {
        @apply border-ui-0 h-full;
        @apply flex relative items-center justify-center;

        &.selected {
            @apply text-ui-text-primary-default;
            @apply font-ui-body-s-bold;

            &:after {
                @apply absolute bottom-[1px] w-full h-ui-1 z-2 content-[''];
                @apply bg-ui-container-primary-default;
            }
        }
    }
}
</style>
