export default (_, inject) => {
    const brandHashedFileNames = JSON.parse('{"modivo":"modivo.ee8b9a.css","eobuwie":"eobuwie.a6bf2b.css"}');
    const assetsPath = '/pwa/assets/';

    const getCssLinkForBrand = brand => {
        if (!(brand in brandHashedFileNames)) {
            return {};
        }

        return {
            hid: 'modivo-ui',
            rel: 'stylesheet',
            href: `${assetsPath}${brandHashedFileNames[brand]}`,
        };
    };

    inject('modivoUI', {
        getCssLinkForBrand,
    });
};
