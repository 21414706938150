import {
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    DOUBLE_SIZE_TYPE,
    HOT_DEAL_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    ONLY_HERE_ATTRIBUTE_NAME,
    OUTLET_ATTRIBUTE_NAME,
    PREMIUM_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import { GOOD_PRICE_ENABLED_LOCALES } from '@configs/product';

import { DEFAULT_SEARCH_CATEGORY } from '@search/configs/defaults/search';

import { COLORS } from '@search/configs/filter-main-colors';

import {
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_OMNIBUS_STRICT_ENABLED,
    IS_CURRENCY_BEFORE_ENABLED,
    IS_OUTLET_ENABLED,
} from '@localeConfig/keys';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';
import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_PRODUCT_CARD,
    IMAGE_TYPE_PRODUCT_CARD_2X,
    IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { PRODUCT_IMAGE_SEX_TYPES } from '@types/ProductImage';

import {
    BADGE_EDGE_VARIANTS_MAP,
    DISCOUNT_TYPES,
    PRICING_DISCOUNT_LABEL_TYPE,
    PRICING_GOOD_PRICE_LABEL_TYPE,
    PRICING_SPECIAL_OFFER_LABEL_TYPE,
    TYPE_DISCOUNT,
    TYPE_GOOD_PRICE,
    TYPE_HOT_DEAL,
    TYPE_NEW,
    TYPE_OCCASION,
    TYPE_OUTLET,
    TYPE_PROMO_ACTION,
    TYPES_FOR_INTERNAL_OFFERS_ONLY,
} from '@types/ProductBadge';

import { ProductBadge } from '@models/ProductBadge/ProductBadge';

import Cache from '@assets/cache';
import { getProductImage } from '@assets/images';
import { getProductVariantLabel, sortProductVariantsBySortOrder } from '@assets/product';
import { getPriceFromPricingData, getPriceFromPricingDataButLocalConfig } from '@assets/pricing';
import { sortBadges } from '@assets/product-badge';
import { getHotDealSticker, getPromoActionSticker } from '@assets/product-promo-stickers';

const MAX_DISPLAY_SIZES = 5;

export default class CatalogProduct {
    constructor(
        {
            id,
            values = {},
            variants = {},
            categories = [],
            family = {},
            attributes = {},
            pricing_data: pricingData = {},
            sponsorship_details: sponsorshipDetails = {},
        },
        locale,
        currency,
        storeViewTimezone,
        $createProductPath,
        $imaginator,
        $abTests,
        $dateHelper,
        $t,
        $localeConfigByKey
    ) {
        this.cache = new Cache();
        this.id = id;
        this.values = values;
        this.variants = variants;
        this.attributes = attributes;
        this.categories = categories;
        this.pricingData = pricingData;
        this.family = family;
        this.locale = locale;
        this.currency = currency;
        this.storeViewTimezone = storeViewTimezone;
        this.sponsorshipDetails = sponsorshipDetails;
        this.$createProductPath = $createProductPath;
        this.$imaginator = $imaginator;
        this.$dateHelper = $dateHelper;
        this.$t = $t;

        this.isPricingFlow = ['lt_LT', 'hu_HU', 'pl_PL', 'sk_SK'].includes(locale);
        this.isOmnibusEnabled = this.isPricingFlow
            ? pricingData.market_type.startsWith('OMNIBUS')
            : $localeConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        this.isCentsEnabled = !$localeConfigByKey(IS_CUT_OFF_THE_CENTS_ENABLED);
        this.isCurrencyBefore = $localeConfigByKey(IS_CURRENCY_BEFORE_ENABLED);
        this.$localeConfigByKey = $localeConfigByKey;
        this.isOutletEnabled = $localeConfigByKey(IS_OUTLET_ENABLED);
    }

    async buildDetails() {
        const productBadgeConfig = await this.getProductBadgeConfig();
        const price = await this.getPrice();

        const product = {
            sku: this.id,
            brand: this.getBrandName(),
            color: this.getColor(),
            colorVariantsLink: this.getColorVariantsLink(),
            colorVariantsCount: this.getColorVariantsCount(),
            images: this.getImages(IMAGE_TYPE_PRODUCT_CARD),
            category: CatalogProduct.getCategory(this.locale, this.categories),
            mainCategoryUrl: CatalogProduct.getMainCategoryUrl(this.locale, this.categories),
            nameDisplay: this.getNameDisplay(),
            shortName: this.getShortName(),
            currency: this.currency,
            price,
            url: this.getUrl(),
            analytics: await this.buildAnalytics(),
            adTechEvents: this.getAdTechEvents(),
            companyAccountId: this.getAdTechCompanyAccountId(),
            productBadgeConfig,
            isSponsored: this.getIsSponsored(),
            mainProductImageSlug: this.getMainImageSlug(),
        };

        product.designSystem = this.buildDesignSystem({ product });

        return product;
    }

    async buildAnalytics() {
        const badges = this.getBadges(DEFAULT_LOCALE);

        const {
            [ONLY_HERE_ATTRIBUTE_NAME]: toRemove,
            [PREMIUM_ATTRIBUTE_NAME]: premiumAttributeToRemove,
            ...badgesRest
        } = badges;

        return {
            sku: this.getSku(),
            name: this.getName(DEFAULT_LOCALE),
            brandName: this.getBrandName(),
            price: await this.getPrice(),
            categories: this.categories,
            color: this.getColor(),
            variants: this.variants,
            badges: badgesRest,
            localeDefault: DEFAULT_LOCALE,
            purpose: [],
            style: [],
            isSponsored: this.getIsSponsored(),
            productBadgeConfig: await this.getProductBadgeConfig(),
        };
    }

    buildDesignSystem({ product }) {
        const {
            promotional,
            regular,
            omnibus,
            isMinimal: isPriceMinimal,
            isOnSale,
        } = product.price;

        const edgeBadges = [];
        let promoBadge = null;

        product.productBadgeConfig.forEach(badge => {
            if (
                !TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type) &&
                (!DISCOUNT_TYPES.includes(badge.type) || !isPriceMinimal)
            ) {
                edgeBadges.push(badge);

                return;
            }

            if (
                !promoBadge &&
                !isPriceMinimal &&
                TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type)
            ) {
                promoBadge = {
                    label: badge.content,
                    color: badge.color,
                    backgroundColor: badge.backgroundColor,
                };
            }
        });

        const { variant_dimension: variantDimension = null } = this.family;

        const sizesArray = Object.values(this.variants)
            .filter(({ stock_quantity }) => stock_quantity > 0)
            .sort(sortProductVariantsBySortOrder)
            .map(variant => getProductVariantLabel(variant, DOUBLE_SIZE_TYPE, variantDimension));

        let sizes = [];

        if (sizesArray.length > 1 && sizesArray.length <= MAX_DISPLAY_SIZES) {
            sizes = sizesArray;
        } else if (
            sizesArray.length === 1 &&
            !['One Size', '00', 'OS', 'NOSIZE'].includes(sizesArray[0])
        ) {
            sizes = sizesArray;
        } else if (sizesArray.length > MAX_DISPLAY_SIZES) {
            sizes = [this.$t('Available in many sizes')];
        }

        const isDiscounted = !isPriceMinimal ? isOnSale : false;

        const priceRegular = regular.show ? regular.formatted : null;

        const priceMinimal = omnibus.formatted || '';

        const showPrevious = this.isOmnibusEnabled ? false : regular.show;

        return {
            id: this.id,
            brand: product.brand,
            name: product.shortName || product.nameDisplay,
            url: product.url,
            color: COLORS[this.values[COLOR_ATTRIBUTE_NAME]?.value.code],
            companyAccountId: this.getAdTechCompanyAccountId(),
            price: {
                final: promotional.formatted,
                regular: priceRegular,
                minimal: priceMinimal,
                isDiscounted,
                isCentsEnabled: this.isCentsEnabled,
                isCurrencyBefore: this.isCurrencyBefore,
                showOmnibus: omnibus.show,
                showPrevious,
                showPricePrefix: isPriceMinimal,
            },
            images: product.images.map((_, index) => {
                const image = getProductImage(
                    product,
                    index,
                    [IMAGE_TYPE_PRODUCT_CARD],
                    IMAGE_TYPE_PRODUCT_CARD
                );

                image.src = image.url;
                delete image.url;

                return image;
            }),
            badges: edgeBadges.map(({ type, content: label }) => ({
                type: BADGE_EDGE_VARIANTS_MAP[type],
                label,
            })),
            promoBadge,
            sizes,
        };
    }

    async getProductBadgeConfig() {
        const productBadgeConfigCacheKey = 'productBadgeConfig';
        const $$productBadgeConfigCache = this.cache.get(productBadgeConfigCacheKey);

        if (typeof $$productBadgeConfigCache !== 'undefined') {
            return $$productBadgeConfigCache;
        }

        const outletBadge = this.getOutletBadge();
        const noveltyBadge = this.getNoveltyBadge();
        const actionStickers = await this.getActionStickers();
        const discountBadges = this.getDiscountBadges();

        const badges = [outletBadge, ...actionStickers, noveltyBadge, ...discountBadges].filter(
            Boolean
        );
        const badgesSorted = sortBadges(badges);

        return this.cache.set(productBadgeConfigCacheKey, badgesSorted);
    }

    getOutletBadge() {
        const isOutlet = this.getIsOutlet();

        if (!isOutlet || !this.isOutletEnabled) {
            return null;
        }

        return new ProductBadge({
            type: TYPE_OUTLET,
            content: 'Outlet',
        }).getPlainObject();
    }

    getNoveltyBadge() {
        const { nowosc } = this.getBadges(this.locale);

        if (!nowosc) {
            return null;
        }

        return new ProductBadge({
            type: TYPE_NEW,
            content: nowosc.label,
        }).getPlainObject();
    }

    getDiscountBadges() {
        if (this.isPricingFlow) {
            const { labels } = this.pricingData;

            return labels.reduce((acc, curr) => {
                if (curr.type === PRICING_DISCOUNT_LABEL_TYPE) {
                    acc.push(
                        new ProductBadge({
                            type: TYPE_DISCOUNT,
                            content: `-${curr.value}%`,
                        }).getPlainObject()
                    );

                    return acc;
                }

                if (curr.type === PRICING_SPECIAL_OFFER_LABEL_TYPE) {
                    acc.push(
                        new ProductBadge({
                            type: TYPE_OCCASION,
                            content: this.$t('Occasion'),
                        }).getPlainObject()
                    );

                    return acc;
                }

                if (curr.type === PRICING_GOOD_PRICE_LABEL_TYPE) {
                    acc.push(
                        new ProductBadge({
                            type: TYPE_GOOD_PRICE,
                            content: this.$t('Good price'),
                        })
                    );

                    return acc;
                }

                return acc;
            }, []);
        }

        const { discount, omnibus_discount: omnibusDiscount } = this.pricingData;

        const discountToUse = this.isOmnibusEnabled ? omnibusDiscount : discount;

        if (discountToUse?.amount >= 5) {
            return [
                new ProductBadge({
                    type: TYPE_DISCOUNT,
                    content: `-${discountToUse.amount}%`,
                }).getPlainObject(),
            ];
        }

        return [];
    }

    async getPrice() {
        const priceCacheKey = 'price';
        const $$priceCache = this.cache.get(priceCacheKey);

        if (typeof $$priceCache !== 'undefined') {
            return $$priceCache;
        }

        const productBadgeConfig = await this.getProductBadgeConfig();
        const hasMarketingBadge = productBadgeConfig.some(badge =>
            TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type)
        );
        const showOmnibusOnGoodPrice = GOOD_PRICE_ENABLED_LOCALES.includes(this.locale);

        let price;

        if (this.isPricingFlow) {
            price = getPriceFromPricingData(this.pricingData, hasMarketingBadge);
        } else {
            price = getPriceFromPricingDataButLocalConfig(
                this.pricingData,
                this.$localeConfigByKey,
                hasMarketingBadge,
                showOmnibusOnGoodPrice
            );
        }

        return this.cache.set(priceCacheKey, price);
    }

    getBadges(locale) {
        return this.values[BADGES_ATTRIBUTE_NAME]?.value[locale] || {};
    }

    getBrandName() {
        return this.values[BRAND_ATTRIBUTE_NAME]?.value.label || '';
    }

    getColor() {
        return this.values[COLOR_ATTRIBUTE_NAME]?.value.label || '';
    }

    getColorVariantsLink() {
        return {
            value: this.values[PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME]?.value || '',
            url: this.attributes[PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME]?.url || '',
        };
    }

    getColorVariantsCount() {
        return this.values[PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME]?.value || 0;
    }

    getImages(imageType, limit) {
        let images = this.values[IMAGES_ATTRIBUTE_NAME]?.value || [];

        if (limit) {
            images = images.slice(0, limit);
        }

        const categorySex = CatalogProduct.getMainCategoryUrl(DEFAULT_LOCALE, this.categories);

        if (categorySex) {
            images = images.filter(({ values: { plec } }) => {
                if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                    return true;
                }

                return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(plec.toLowerCase());
            });
        }

        return images.map(image => {
            const result = {
                family: image.family,
            };

            let sourceImageTypes = [imageType];

            if (imageType === IMAGE_TYPE_PRODUCT_CARD) {
                sourceImageTypes = [
                    IMAGE_TYPE_PRODUCT_CARD,
                    IMAGE_TYPE_PRODUCT_CARD_2X,
                    IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
                ];
            }

            result[imageType] = this.$imaginator.getImage(
                image.url,
                this.getUrlKey(),
                this.getImageAlt(),
                imageType,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                sourceImageTypes
            );

            return result;
        });
    }

    getNameDisplay() {
        const name = this.values[NAME_DISPLAY_ATTRIBUTE_NAME]?.value[this.locale];

        return name || this.values[NAME_ATTRIBUTE_NAME]?.value[this.locale] || '';
    }

    getName(locale = this.locale) {
        return this.values[NAME_ATTRIBUTE_NAME]?.value?.[locale] || '';
    }

    getShortName() {
        return this.values[SHORT_NAME_ATTRIBUTE_NAME]?.value[this.locale];
    }

    getUrl() {
        return this.$createProductPath(this.getUrlKey());
    }

    getUrlKey() {
        return this.values[URL_KEY_ATTRIBUTE_NAME]?.value[this.locale] || '';
    }

    getIsSponsored() {
        return !!Object.keys(this.sponsorshipDetails).length;
    }

    getAdTechEvents() {
        const {
            click_event_urls: onClickBeacons = [],
            view_event_urls: onViewBeacons = [],
            load_event_urls: onLoadBeacons = [],
        } = this.sponsorshipDetails;

        return {
            onClickBeacons,
            onViewBeacons,
            onLoadBeacons,
        };
    }

    getAdTechCompanyAccountId() {
        const { account_id = '' } = this.sponsorshipDetails;

        return account_id;
    }

    getIsOutlet() {
        return !!parseInt(this.values[OUTLET_ATTRIBUTE_NAME]?.value?.code, 10);
    }

    getSku() {
        return this.values[SKU_ATTRIBUTE_NAME]?.value || '';
    }

    getImageAlt() {
        return `${this.getBrandName()} ${this.getName()}`.trim();
    }

    getMainImageSlug() {
        const images = this.values[IMAGES_ATTRIBUTE_NAME]?.value || [];
        const mainProductImg = images[0]?.url;

        return mainProductImg || null;
    }

    async getActionStickers() {
        const isOutlet = this.getIsOutlet();

        const omnibusAmount = this.pricingData?.omnibus_price?.amount;

        const shouldDisplayActionSticker = (!this.isOmnibusEnabled || !!omnibusAmount) && !isOutlet;

        const badges = [];

        if (!shouldDisplayActionSticker) {
            return badges;
        }

        const hotDealAction = await this.getHotDeal();

        const {
            badgeBackgroundColor: hotDealBackgroundColor,
            badgeTextColor: hotDealTextColor,
            formattedBadgeContent: hotDealLabel,
            isInternal: isHotDealInternal,
        } = hotDealAction || {};

        if (hotDealBackgroundColor && hotDealLabel) {
            badges.push(
                new ProductBadge({
                    type: TYPE_HOT_DEAL,
                    backgroundColor: hotDealBackgroundColor,
                    color: hotDealTextColor,
                    content: hotDealLabel,
                    isInternal: isHotDealInternal,
                }).getPlainObject()
            );
        }

        const promoAction = await this.getPromoAction();

        const {
            badgeBackgroundColor,
            badgeTextColor,
            formattedBadgeContent,
            isInternal: isPromoInternal,
            endDate,
        } = promoAction || {};

        if (badgeBackgroundColor && formattedBadgeContent) {
            badges.push(
                new ProductBadge({
                    type: TYPE_PROMO_ACTION,
                    backgroundColor: badgeBackgroundColor,
                    color: badgeTextColor,
                    content: formattedBadgeContent,
                    isInternal: isPromoInternal,
                    data: {
                        endDate,
                    },
                }).getPlainObject()
            );
        }

        return badges;
    }

    async getHotDeal() {
        const hotDealCacheKey = 'hotDeal';
        const $$hotDealCache = this.cache.get(hotDealCacheKey);

        if (typeof $$hotDealCache !== 'undefined') {
            return $$hotDealCache;
        }

        const { storeViewTimezone, variants, locale, $dateHelper } = this;

        const hotDealValue = this.values[HOT_DEAL_ATTRIBUTE_NAME]?.value[locale] || {};

        const hotDeal = await getHotDealSticker({
            storeViewTimezone,
            locale,
            variants,
            hotDealValue,
            $dateHelper,
        });

        return this.cache.set(hotDealCacheKey, hotDeal);
    }

    async getPromoAction() {
        const promoActionCacheKey = 'promoAction';

        const { storeViewTimezone, locale, variants, $dateHelper } = this;

        const promoActionValue = this.values[STICKER_ATTRIBUTE_NAME]?.value[locale] || {};

        const promoAction = await getPromoActionSticker({
            locale,
            variants,
            promoActionValue,
            storeViewTimezone,
            $dateHelper,
        });

        return this.cache.set(promoActionCacheKey, promoAction);
    }

    static getMainCategoryUrl(locale, categories = []) {
        return categories[0]?.[1]?.translations?.[locale]?.url || DEFAULT_SEARCH_CATEGORY;
    }

    static getCategory(locale, categories = []) {
        if (!categories.length) {
            return null;
        }

        const firstCategories = categories[0] || [];
        const category = firstCategories[firstCategories.length - 1] || null;

        return category?.translations?.[locale] || null;
    }

    static extendProductWithCategoriesData(product, categories, locale) {
        const categorySex = CatalogProduct.getMainCategoryUrl(DEFAULT_LOCALE, categories);

        const filteredImages = product.images.filter(({ values: { plec } = {} }) => {
            if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                return true;
            }

            return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(plec.toLowerCase());
        });

        return {
            ...product,
            images: categorySex ? filteredImages : product.images,
            analytics: {
                ...product.analytics,
                categories,
            },
            category: CatalogProduct.getCategory(locale, categories),
            mainCategoryUrl: CatalogProduct.getMainCategoryUrl(locale, categories),
            categories,
        };
    }
}
