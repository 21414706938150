import {
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_PARAMETER_NEAREST_PICKUP_POINTS,
    MEGATRON_CHECKOUT_GET_SUCCESS_PAGE_DATA_OPERATION_NAME,
    MEGATRON_CHECKOUT_GET_REPAYMENT_ORDER_DETAILS_OPERATION_NAME,
} from '@configs/megatron-client';

import { ERROR_ACTION_TAG_NAME, UNKNOWN_ERROR } from '@types/Errors';

const getErrorTags = action => ({
    [ERROR_ACTION_TAG_NAME]: `services.checkout.${action}`,
});

export default ({ megatronClient, normalizeMegatronResponse, $errorHandler }) => ({
    async getAvailableCountries() {
        const { data } = await megatronClient.get('v1/availableCountries', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        return data?.countries || [];
    },

    async getClientType(orderHash) {
        const ERROR_TAGS = getErrorTags('getClientType');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get('v1/getClientType', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            if (!error) {
                return data?.clientType || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getSuccessPageData(orderHash) {
        const ERROR_TAGS = getErrorTags('getSuccessPageData');
        const payload = { orderHash };

        try {
            const response = await megatronClient.get('v1/getSuccessPageData', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_CHECKOUT_GET_SUCCESS_PAGE_DATA_OPERATION_NAME
            );

            if (!error) {
                const { thanksPageData = null, clientType = null } = data || {};

                if (!thanksPageData || !clientType) {
                    return null;
                }

                return {
                    ...thanksPageData,
                    ...clientType,
                };
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getRepaymentOrderDetails(orderHash) {
        const ERROR_TAGS = getErrorTags('getRepaymentOrderDetails');
        const payload = { orderHash };

        try {
            const response = await megatronClient.get('v1/getRepaymentOrderDetails', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            const { data, error, status } = await normalizeMegatronResponse(
                response,
                MEGATRON_CHECKOUT_GET_REPAYMENT_ORDER_DETAILS_OPERATION_NAME
            );

            if (!error) {
                const { thanksPageData = null, clientType = null } = data || {};

                if (!thanksPageData || !clientType) {
                    return null;
                }

                return { ...thanksPageData, ...clientType };
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getOrderRepayLink(orderHash) {
        const ERROR_TAGS = getErrorTags('getOrderRepayLink');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get('v1/getOrderRepayLink', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            if (!error) {
                const { success, redirect_uri: redirectUri } = data?.repayLink || {};

                return { success, redirectUri };
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return {};
    },

    async getRepayLinkByPaymentMethod(orderHash, paymentMethod) {
        const ERROR_TAGS = getErrorTags('getRepayLinkByPaymentMethod');
        const payload = { orderHash, paymentMethod };

        try {
            const { data, error, status } = await megatronClient.get(
                'v1/getRepayLinkByPaymentMethod',
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                    payload,
                }
            );

            if (!error) {
                const { success, redirect_uri: redirectUri } = data?.repayLinkByPaymentMethod || {};

                return {
                    success,
                    redirectUri,
                };
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return {};
    },

    async getOrderRepayOptions(orderHash) {
        const ERROR_TAGS = getErrorTags('getOrderRepayOptions');
        const payload = { orderHash };

        try {
            const { data, error, status } = await megatronClient.get('v1/getOrderRepayOptions', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            if (!error) {
                return data?.repayPaymentMethods?.paymentMethod || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getPaymentGatewayData(paymentDataForGateway) {
        const ERROR_TAGS = getErrorTags('getPaymentGatewayData');
        const {
            paymentMethodCode,
            orderNumber,
            redirectUrl,
            additionalData,
        } = paymentDataForGateway;

        const payload = {
            paymentMethodCode,
            orderNumber,
            redirectUrl,
        };

        if (additionalData.length) {
            payload.input = {
                additionalData,
            };
        }

        try {
            const { data, error, status } = await megatronClient.post('v1/paymentGatewayData', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            if (!error) {
                return data?.paymentGateway || null;
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return null;
    },

    async getInpostCities() {
        const { data } = await megatronClient.get('v1/inpostCities', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        return data?.inpostCities || [];
    },

    async getInpostPoints(city) {
        const { data } = await megatronClient.get('v1/inpostPoints', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                city,
            },
        });

        return data?.inpostPoints?.items || [];
    },

    async getInpostPoint(pointName) {
        const { data } = await megatronClient.get('v1/inpostPoint', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                name: pointName,
            },
        });

        const [point = null] = data?.inpostPoints?.items || [];

        return point;
    },

    async getPickupLapostePoints(city, zipCode, address) {
        const ERROR_TAGS = getErrorTags('getPickupLapostePoints');
        const payload = {
            city,
            zipCode,
            address,
        };

        try {
            const { data, error, status } = await megatronClient.get('v1/laPosteshippingPoints', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
                payload,
            });

            if (!error) {
                return data?.laPosteshippingPoints?.items || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    payload,
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS, { payload });
        }

        return [];
    },

    async getPickupPointsCities(code) {
        const { data } = await megatronClient.get('v1/shippingPointsCities', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                code,
            },
        });

        return data?.shippingPointsCities || [];
    },

    async getPickupPointsDpdParcelShopCities() {
        const { data } = await megatronClient.get('v1/pickupPointsCities', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        return data?.dpdParcelShopCities || [];
    },

    async getPickupPointsSpeedyParcelShopCities() {
        const { data } = await megatronClient.get('v1/pickupPointsSpeedyParcelShopCities', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
        });

        return data?.speedyParcelShopCities || [];
    },

    async getPickupPoints(city, code) {
        const { data } = await megatronClient.get('v1/shippingPoints', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                city,
                code,
            },
        });

        return data?.shippingPoints?.items || [];
    },

    async getPickupPointsDpdParcelShop(city) {
        const { data } = await megatronClient.get('v1/pickupPoints', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                city,
            },
        });

        return data?.dpdParcelShopPoints?.items || [];
    },

    async getPickupPointsSpeedyParcelShop(cityId) {
        const { data } = await megatronClient.get('v1/pickupPointsSpeedyParcelShop', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                cityId,
            },
        });

        return data?.speedyParcelShopPoints?.items || [];
    },

    async getShippingPoint(shippingPointId) {
        const { data } = await megatronClient.get('v1/shippingPoint', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                shippingPointId,
            },
        });

        return data?.shippingPoints?.items[0] || null;
    },

    async getPickupPointDpdParcelShop(parcelshopId) {
        const { data } = await megatronClient.get('v1/pickupPoint', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                parcelshopId,
            },
        });

        const [pickupPoint = null] = data?.dpdParcelShopPoints?.items || [];

        return pickupPoint;
    },

    async getPickupPointSpeedyParcelShop(parcelshopId) {
        const { data } = await megatronClient.get('v1/pickupPointSpeedyParcelShop', {
            ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            payload: {
                parcelshopId,
            },
        });

        const [pickupPoint = null] = data?.speedyParcelShopPoints?.items || [];

        return pickupPoint;
    },

    async getNearestPickupPoints(address) {
        const { postcode, carriers, city, street, streetNumber } = address;
        const { data } = await megatronClient.get(
            // eslint-disable-next-line max-len
            `nearest-pickup-points?postCode=${postcode}&carriers[]=${carriers}&city=${city}&street=${street}&streetNumber=${streetNumber}&country=pl`,
            {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_NEAREST_PICKUP_POINTS,
            }
        );

        return data?.['hydra:member'] || [];
    },

    async getPaymentMethods() {
        const ERROR_TAGS = getErrorTags('getPaymentMethods');

        try {
            const { data, error, status } = await megatronClient.get('v1/getPaymentMethods', {
                ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_MAGENTO,
            });

            if (!error) {
                return data?.paymentMethods || [];
            }

            $errorHandler.captureError(
                new Error(error?.message || UNKNOWN_ERROR, {
                    cause: error,
                }),
                ERROR_TAGS,
                {
                    data,
                    error,
                    status,
                }
            );
        } catch (err) {
            $errorHandler.captureError(err, ERROR_TAGS);
        }

        return [];
    },
});
