import {
    WEB_MOD_CART_1,
    WEB_MOD_CATEGORY_1,
    WEB_MOD_EMPTY_CART_1,
    WEB_MOD_ERROR_PAGE_1,
    WEB_MOD_PDP_1,
    WEB_MOD_PDP_2,
    WEB_MOD_PDP_SIMILAR,
    WEB_MOD_PDP_UNAVBL_1,
    WEB_MOD_PRECART_1,
    WEB_MOD_SEARCH_RESULTS_1,
    WEB_PDP_PRODUCT_NOTIFICATION,
} from '@types/Synerise';

import { DEFAULT_ZOWIE_CHAT_SRC } from '@assets/zowie-chat';

export const createDefaultGlobalConfig = () => ({
    optimizelyEnabledPages: [],
    isMagicLinkEnabled: false,
    isZowieChatEnabled: false,
    zowieChatEnabledPages: [],
    zowieScriptSrc: DEFAULT_ZOWIE_CHAT_SRC,
    zowieChatInstanceId: '',
    freeShippingTresholdInternal: null,
    freeShippingTresholdPartner: null,
    minimalShippingCostInternal: '',
    shippingTimeInfo: '',
    isAvatarClientAddressEnabled: false,
    isAccountVerificationEnabled: false,
    freeDeliveryText: '',
    synthroneScriptUrl: '',
    synthroneBrands: '',
});

export const createDefaultHelpCenterConfig = () => ({
    contactFormLink: '',
});

export const createDefaultSyneriseConfig = () => ({
    apiKey: '',
    [WEB_MOD_PDP_1]: '',
    [WEB_MOD_PDP_2]: '',
    [WEB_MOD_PDP_UNAVBL_1]: '',
    [WEB_MOD_PRECART_1]: '',
    [WEB_MOD_CART_1]: '',
    [WEB_MOD_PDP_SIMILAR]: '',
    [WEB_PDP_PRODUCT_NOTIFICATION]: '',
    [WEB_MOD_CATEGORY_1]: '',
    [WEB_MOD_SEARCH_RESULTS_1]: '',
    [WEB_MOD_ERROR_PAGE_1]: '',
    [WEB_MOD_EMPTY_CART_1]: '',
});

export default () => ({
    localeConfig: {},
    storeViews: [],
    storeView: {},
    baseUrl: '',
    cookies: '',
    globalConfig: {
        ...createDefaultGlobalConfig(),
    },
    helpCenterConfig: {
        ...createDefaultHelpCenterConfig(),
    },
    syneriseConfig: {
        ...createDefaultSyneriseConfig(),
    },
    googleAnalyticsConfig: {},
});
