<template>
    <div class="header-search-button">
        <div class="search-button" @click.prevent.stop="openAutocomplete()">
            <Icon :icon="Search" />

            <span class="button-text">
                {{ placeholder }}
            </span>
            <ButtonIcon
                v-if="isVisualSearchTestOn"
                class="camera-icon"
                :variant="BUTTON_ICON_VARIANT"
                @click.prevent.stop="openFileUploadModal()"
            >
                <Icon :icon="Camera" />
            </ButtonIcon>
        </div>

        <HeaderSideModal v-if="isAutocompleteOpen && isMobile" :is-open="isAutocompleteOpen">
            <template #header>
                <div class="autocomplete-header">
                    <HeaderSearchInput
                        :placeholder="placeholder"
                        :focus-on-mount="true"
                        class="search-input"
                    />

                    <Divider />
                </div>
            </template>

            <HeaderAutocomplete />
        </HeaderSideModal>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { MODAL_SIMILAR_PRODUCTS_VS } from '@configs/modals';

import { VISUAL_SEARCH_INPUT_BUTTON_CLICK } from '@analytics-modules/catalog/types/Events';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';

import { VISUAL_SEARCH_BY_IMG } from '@search/assets/ab-tests';
import { isAbTestOn } from '@search/utils/ab-tests';

import { Search } from '@modivo-ui/icons/v2/navigation';
import { Camera } from '@modivo-ui/icons/v2/action';

import HeaderSearchInput from '@header-atoms/HeaderSearchInput/HeaderSearchInput';

import { Icon } from '@modivo-ui/components/Icon/v1';
import { Divider } from '@modivo-ui/components/Divider/v1';
import { ButtonIcon, BUTTON_ICON_VARIANTS } from '@modivo-ui/components/ButtonIcon/v1';

const { mapActions: mapAutocompleteActions } = createNamespacedHelpers('header/autocomplete');

export default {
    name: 'HeaderSearchButton',

    components: {
        Icon,
        Divider,
        HeaderSearchInput,
        HeaderSideModal: () => ({
            component: import(
                /* webpackChunkName: "header-side-modal" */
                '@header-molecules/HeaderSideModal/HeaderSideModal'
            ),
        }),
        HeaderAutocomplete: () => ({
            component: import(
                /* webpackChunkName: "header-autocomplete" */
                '@header-organisms/HeaderAutocomplete/HeaderAutocomplete'
            ),
        }),
        ButtonIcon,
    },

    props: {
        placeholder: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState(['isMobile']),

        isAutocompleteOpen() {
            return this.$modals.isOpen('autocomplete');
        },

        isVisualSearchTestOn() {
            return isAbTestOn(this.$abTests, VISUAL_SEARCH_BY_IMG);
        },
    },

    mounted() {
        this.checkIfIsNewAutocomplete();
    },

    beforeCreate() {
        this.Search = Search;
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.Camera = Camera;
    },

    methods: {
        ...mapAutocompleteActions(['openAutocomplete', 'checkIfIsNewAutocomplete']),
        openFileUploadModal() {
            this.$modals.open(MODAL_SIMILAR_PRODUCTS_VS);

            this.$analytics.moduleEmit(CATALOG_MODULE_NAME, VISUAL_SEARCH_INPUT_BUTTON_CLICK);
        },
    },
};
</script>

<style scoped lang="scss">
.header-search-button {
    .search-button {
        @apply flex items-center w-full rounded-ui-3 h-ui-11 pl-ui-3 gap-x-ui-2;
        @apply bg-ui-container-secondary-default;
        @apply text-ui-text-primary-default;
    }

    .search-input {
        @apply m-ui-4;
    }

    .button-text {
        @apply text-ui-text-secondary-default;
    }

    .camera-icon {
        @apply ml-auto;
    }
}
</style>
