<template>
    <BaseTooltip
        :position="POSITION_BOTTOM_LEFT"
        padding="8px"
        :is-mobile="isMobile"
        :is-tooltip-enabled="isTooltipEnabled"
        class="wishlist-tooltip"
        @mouseenter.native="sendEvent()"
    >
        <HeaderActionButton
            :icon="Favourite"
            :path="wishlistPath"
            :digit="wishlistCount"
            :label="$t('Wishlist')"
            :analytics-click-event="HEADER_WISHLIST_CLICK"
        />
        <template #tooltipContent>
            <div class="tooltip-content">
                <BodyText :variant="BODY_TEXT_VARIANT" class="heading">
                    {{ headingCopy }}
                </BodyText>
                <BodyText class="text">
                    {{
                        $t(
                            // eslint-disable-next-line max-len
                            'Find out about news and current promotions faster.'
                        )
                    }}
                </BodyText>
                <ButtonSmall :wide="true" @click="openNewsletterModal()">
                    {{ $t('Subscribe to the newsletter') }}
                </ButtonSmall>
            </div>
        </template>
    </BaseTooltip>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { POSITION_BOTTOM_LEFT } from '@types/Tooltip';

import {
    MODAL_NEWSLETTER_NAME,
    SHOULD_OPEN_MODAL_ON_CLICK,
    MODAL_NEWSLETTER_RELEASER,
    NEWSLETTER_RELEASER_WISHLIST_HOVER,
    GDPR_LAYOUT_HANDLE,
} from '@configs/modals';
import { WISHLIST } from '@configs/gdpr';
import { isNewsletterCopyTestOn } from '@configs/newsletter';

import { WISHLIST_PAGE } from '@router/paths';

import { HEADER_WISHLIST_CLICK } from '@analytics-types/Events';
import { MODULE_NAME } from '@analytics-modules/client/meta';
import { NEWSLETTER_TRIGGER_VIEW } from '@analytics-modules/client/types/Events';
import { WISHLIST_HOVER } from '@analytics-modules/client/types/Labels';

import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';

import HeaderActionButton from '@header-atoms/HeaderActionButton/HeaderActionButton';

import { Favourite } from '@modivo-ui/icons/v1/other';
import { ButtonSmall } from '@modivo-ui/components/ButtonSmall/v1';
import { BodyText, BODY_TEXT_VARIANTS } from '@modivo-ui/components/BodyText/v1';

const { mapState: mapCustomerState, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);

export default {
    name: 'WishlistTooltip',

    components: {
        HeaderActionButton,
        BaseTooltip,
        ButtonSmall,
        BodyText,
    },

    computed: {
        ...mapCustomerGetters(['wishlistCount', 'isLoggedIn']),
        ...mapCustomerState(['isCustomerSubscribedToNewsletter']),
        ...mapState(['isMobile']),

        isTooltipEnabled() {
            return (
                this.wishlistCount > 0 && this.isLoggedIn && !this.isCustomerSubscribedToNewsletter
            );
        },

        headingCopy() {
            const abTestCopy =
                // eslint-disable-next-line max-len
                'I want to subscribe to the Newsletter and receive a 15% discount on subsequent purchases';

            return this.$t(
                isNewsletterCopyTestOn(this.$abTests)
                    ? abTestCopy
                    : 'Stay up to date with price reductions on your favourite products!'
            );
        },
    },

    beforeCreate() {
        this.wishlistPath = `/${WISHLIST_PAGE}`;
        this.Favourite = Favourite;
        this.HEADER_WISHLIST_CLICK = HEADER_WISHLIST_CLICK;
        this.POSITION_BOTTOM_LEFT = POSITION_BOTTOM_LEFT;
        this.BODY_TEXT_VARIANT = BODY_TEXT_VARIANTS.BOLD;
    },

    methods: {
        openNewsletterModal() {
            this.$modals.open(MODAL_NEWSLETTER_NAME, {
                [SHOULD_OPEN_MODAL_ON_CLICK]: true,
                [MODAL_NEWSLETTER_RELEASER]: NEWSLETTER_RELEASER_WISHLIST_HOVER,
                [GDPR_LAYOUT_HANDLE]: WISHLIST,
            });
        },

        sendEvent() {
            this.$analytics.moduleEmit(MODULE_NAME, NEWSLETTER_TRIGGER_VIEW, {
                eventLabel: WISHLIST_HOVER,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// todo update design - Checkout?
.wishlist-tooltip {
    .tooltip-content {
        width: theme('customVariables.headerWishlistTooltip.width');

        .heading {
            @apply leading-ui-24;
        }

        .text {
            @apply text-ui-12 leading-ui-18 pb-ui-4 pt-ui-1;
        }
    }
}
</style>
