import {
    ATTRIBUTE_TYPE_ASSETS_COLLECTION,
    ATTRIBUTE_TYPE_BOOLEAN,
    ATTRIBUTE_TYPE_MULTI_SELECT,
    ATTRIBUTE_TYPE_NUMBER,
    ATTRIBUTE_TYPE_PRICE_COLLECTION,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY,
    ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION,
    ATTRIBUTE_TYPE_SIMPLE_SELECT,
    ATTRIBUTE_TYPE_TEXT,
} from '@configs/product-attribute-types';
import {
    ACTIVE_ATTRIBUTE_NAME,
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    COLOR_ARRAY_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    DISCOUNT_ATTRIBUTE_NAME,
    ECO_FRIENDLY_ATTRIBUTE_NAME,
    FASON_ATTRIBUTE_NAME,
    FINAL_PRICE_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    LOYALTY_CLUB_ATTRIBUTE_NAME,
    MODEL_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    OCCASION_ATTRIBUTE_NAME,
    OUTLET_ATTRIBUTE_NAME,
    OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
    OMNIBUS_PRICE_ATTRIBUTE_NAME,
    ONLY_HERE_ATTRIBUTE_NAME,
    PREMIUM_ATTRIBUTE_NAME,
    PRICE_ATTRIBUTE_NAME,
    PRODUCER_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    PURPOSE_ATTRIBUTE_NAME,
    SERIES_NAME_ATTRIBUTE_NAME,
    SEX_NAME_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    SIZE_SUGGESTION_ATTRIBUTE_NAME,
    SIZE_TABLE_ATTRIBUTE_NAME,
    SIZE_TYPE_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    STYLE_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    VIDEO_ATTRIBUTE_NAME,
    DOUBLE_SIZE_TYPE,
} from '@configs/product-attribute-names';

import { IS_ECO_FRIENDLY_VALUE_CODE } from '@configs/eco-friendly';
import { LOYALTY_CLUB_PRODUCT_FALSE } from '@configs/loyalty-club';
import { PRODUCT_ONE_SIZE_LABEL } from '@configs/product';
import { DEFAULT_SEARCH_CATEGORY } from '@search/configs/defaults/search';

import { COLORS } from '@search/configs/filter-main-colors';

import {
    IS_OMNIBUS_STRICT_ENABLED,
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
    IS_DISCOUNT_ENABLED,
    IS_OUTLET_ENABLED,
} from '@localeConfig/keys';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';
import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_BRAND,
    IMAGE_TYPE_CART,
    IMAGE_TYPE_PRODUCT,
    IMAGE_TYPE_PRODUCT_CARD,
    IMAGE_TYPE_PRODUCT_CARD_2X,
    IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
    IMAGE_TYPE_THUMBNAIL,
    IMAGE_TYPE_ZOOM_1536w_2048h,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { PRODUCT_IMAGE_SEX_TYPES } from '@types/ProductImage';

import {
    TYPE_DISCOUNT,
    TYPE_ECO_FRIENDLY,
    TYPE_HOT_DEAL,
    TYPE_NEW,
    TYPE_OCCASION,
    TYPE_OUTLET,
    TYPE_ONLY_HERE,
    TYPE_PROMO_ACTION,
    TYPES_FOR_INTERNAL_OFFERS_ONLY,
    BADGE_EDGE_VARIANTS_MAP,
    DISCOUNT_TYPES,
} from '@types/ProductBadge';

import { ProductBadge } from '@models/ProductBadge/ProductBadge';

import Cache from '@assets/cache';
import { getPlaceholder, getProductImage } from '@assets/images';
import { getPrice, getProductVariantLabel, sortProductVariantsBySortOrder } from '@assets/product';
import { getVimeoVideoUrl, getYoutubeVideoUrl } from '@assets/video';
import { getDoubleSize } from '@assets/double-size';
import { sortBadges } from '@assets/product-badge';
import { getHotDealSticker, getPromoActionSticker } from '@assets/product-promo-stickers';

const PRICE_TYPE_MINIMAL = 'minimal';
const OMNIBUS_OCCASION_TRESHOLD = 5;

const MAX_DISPLAY_SIZES = 5;

export default class Product {
    constructor(
        {
            id,
            values = {},
            variants = {},
            categories = [],
            family = {},
            associations = [],
            attributes = {},
            sponsorship_details: sponsorshipDetails = {},
        },
        locale,
        currency,
        storeViewTimezone,
        $createProductPath,
        $createCategoryPath,
        $stringifyCategoryUrl,
        $imaginator,
        $getStaticFileUrlWithHash,
        $abTests,
        $dateHelper,
        $t,
        $localeConfigByKey,
        withDesignSystem = false
    ) {
        const valuesWithAttributes = Object.fromEntries(
            Object.entries(values).map(([key, value]) => {
                return [
                    key,
                    {
                        ...value,
                        attribute: attributes?.[key] || value.attribute,
                    },
                ];
            })
        );

        this.cache = new Cache();
        this.id = id;
        this.values = valuesWithAttributes;
        this.attributes = attributes;
        this.variants = variants;
        this.categories = categories;
        this.family = family;
        this.locale = locale;
        this.currency = currency;
        this.storeViewTimezone = storeViewTimezone;
        this.associations = associations;
        this.sponsorshipDetails = sponsorshipDetails;
        this.$createProductPath = $createProductPath;
        this.$createCategoryPath = $createCategoryPath;
        this.$stringifyCategoryUrl = $stringifyCategoryUrl;
        this.$imaginator = $imaginator;
        this.$getStaticFileUrlWithHash = $getStaticFileUrlWithHash;
        this.$dateHelper = $dateHelper;
        this.$t = $t;
        this.$abTests = $abTests;
        this.$localeConfigByKey = $localeConfigByKey;

        this.isOutletEnabled = $localeConfigByKey(IS_OUTLET_ENABLED);
        this.isOmnibusEnabled = $localeConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        this.isDiscountEnabled = $localeConfigByKey(IS_DISCOUNT_ENABLED);
        this.isCentsEnabled = !$localeConfigByKey(IS_CUT_OFF_THE_CENTS_ENABLED);
        this.isRegularPriceEnabled = $localeConfigByKey(IS_REGULAR_PRICE_ENABLED);

        this.withDesignSystem = withDesignSystem;
    }

    async buildDetails() {
        const promoSticker = await this.getPromoSticker();
        const hasProductBadge = await this.hasProductBadge();
        const productBadgeConfig = await this.getProductBadgeConfig();

        const product = {
            badges: this.getBadgesVisible(),
            brand: this.getBrandName(),
            brandName: this.getBrandName(),
            brandImage: this.getBrandImage(),
            brandUrl: this.getBrandUrl(),
            color: this.getColor(),
            colorVariantsLink: this.getColorVariantsLink(),
            colorVariantsCount: this.getColorVariantsCount(),
            fason: this.getFason(),
            images: this.getImages([
                IMAGE_TYPE_THUMBNAIL,
                IMAGE_TYPE_PRODUCT,
                IMAGE_TYPE_PRODUCT_CARD,
                IMAGE_TYPE_ZOOM_1536w_2048h,
                IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
                IMAGE_TYPE_CART,
            ]),
            isActive: this.getIsActive(),
            isOneSize: this.getIsOneSize(),
            category: Product.getCategory(this.locale, this.categories),
            mainCategoryUrl: Product.getMainCategoryUrl(this.locale, this.categories),
            model: this.getModel(),
            name: this.getName(),
            nameDisplay: this.getNameDisplay(),
            shortName: this.getShortName(),
            currency: this.currency,
            price: this.getPrice(),
            promoSticker,
            hasPromoSticker: Product.hasPromoSticker(promoSticker),
            seriesName: this.getSeriesName(),
            sizeType: this.getSizeType(),
            sizeTypeCode: this.getSizeTypeCode(),
            imageSizeTable: this.getImageSizeTable(),
            sku: this.getSku(),
            url: this.getUrl(),
            urlKey: this.getUrlKey(),
            video: this.getVideo(),
            family: this.family,
            variants: this.variants,
            values: this.values,
            attributes: this.attributes,
            categories: this.categories,
            associations: this.associations,
            relatedIndexes: this.getRelatedIndexes(),
            analytics: await this.buildAnalytics(),
            loyaltyClubPromo: this.getLoyaltyClubAttribute(),
            path: this.getUrl(),
            id: this.id,
            isPremium: this.getIsPremium(),
            sizeSuggestion: this.getSizeSuggestion(),
            adTechEvents: this.getAdTechEvents(),
            companyAccountId: this.getAdTechCompanyAccountId(),
            hasProductBadge,
            productBadgeConfig,
            isSponsored: this.getIsSponsored(),
            hasOneVariant: this.getHasOneVariant(),
        };

        if (this.withDesignSystem) {
            product.designSystem = this.buildDesignSystem({ product });
        }

        return product;
    }

    async buildAnalytics() {
        return {
            sku: this.getSku(DEFAULT_LOCALE),
            name: this.getName(DEFAULT_LOCALE),
            nameDisplay: this.getNameDisplay(DEFAULT_LOCALE),
            shortName: this.getShortName(DEFAULT_LOCALE),
            price: this.getPrice(),
            brandName: this.getBrandName(DEFAULT_LOCALE),
            categories: this.categories,
            color: this.getColor(DEFAULT_LOCALE),
            fason: this.getFason(DEFAULT_LOCALE),
            variants: this.variants,
            isActive: this.getIsActive(DEFAULT_LOCALE),
            model: this.getModel(DEFAULT_LOCALE),
            seriesName: this.getSeriesName(DEFAULT_LOCALE),
            badges: this.getBadgesVisible(DEFAULT_LOCALE),
            occasion: this.getOccasion(DEFAULT_LOCALE),
            outlet: this.getOutlet(),
            urlKey: this.getUrlKey(DEFAULT_LOCALE),
            localeDefault: DEFAULT_LOCALE,
            sexName: this.getSexName(DEFAULT_LOCALE),
            purpose: this.getPurpose(DEFAULT_LOCALE),
            style: this.getStyle(DEFAULT_LOCALE),
            isPremium: this.getIsPremium(DEFAULT_LOCALE),
            isSponsored: this.getIsSponsored(),
            productBadgeConfig: await this.getProductBadgeConfig(this.locale, true, true),
            mainImageType: this.getMainImageType(),
        };
    }

    buildDesignSystem({ product }) {
        const {
            discount,
            promotional,
            regular,
            omnibus,
            isMinimal: isPriceMinimal,
        } = product.price;

        const isDiscountFromPrice = this.isOmnibusEnabled
            ? omnibus?.amount > promotional.amount
            : promotional.amount < regular.amount;

        const isDiscounted = discount && !isPriceMinimal ? isDiscountFromPrice : false;

        const arePricesTheSame =
            promotional.amount === regular.amount && promotional.amount === omnibus.amount;

        const edgeBadges = [];
        let promoBadge = null;

        product.productBadgeConfig.forEach(badge => {
            if (
                !TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type) &&
                (!DISCOUNT_TYPES.includes(badge.type) || !isPriceMinimal)
            ) {
                edgeBadges.push(badge);

                return;
            }

            if (
                !promoBadge &&
                !isPriceMinimal &&
                TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badge.type)
            ) {
                promoBadge = {
                    label: badge.content,
                    color: badge.color,
                    backgroundColor: badge.backgroundColor,
                };
            }
        });

        const { variant_dimension: variantDimension = null } = this.family;

        const sizesArray = Object.values(product.variants)
            .filter(({ stock_quantity }) => stock_quantity > 0)
            .sort(sortProductVariantsBySortOrder)
            .map(variant => getProductVariantLabel(variant, DOUBLE_SIZE_TYPE, variantDimension));

        let sizes = [];

        if (sizesArray.length > 1 && sizesArray.length <= MAX_DISPLAY_SIZES) {
            sizes = sizesArray;
        } else if (
            sizesArray.length === 1 &&
            ![PRODUCT_ONE_SIZE_LABEL, '00', 'OS', 'NOSIZE'].includes(sizesArray[0])
        ) {
            sizes = sizesArray;
        } else if (sizesArray.length > MAX_DISPLAY_SIZES) {
            sizes = [this.$t('Available in many sizes')];
        }

        const priceRegular = this.isRegularPriceEnabled ? product.price.regular.formatted : null;

        const priceMinimal =
            arePricesTheSame || isPriceMinimal ? null : product.price.omnibus?.formatted || '';

        const showOmnibus = this.isOmnibusEnabled
            ? !arePricesTheSame && priceMinimal && (isDiscounted || promoBadge)
            : false;

        const showPrevious = this.isRegularPriceEnabled && !this.isOmnibusEnabled && isDiscounted;

        return {
            id: product.id,
            brand: product.brandName,
            name: product.shortName || product.nameDisplay,
            url: product.url,
            color: COLORS[product.values.main_color?.value.code],
            companyAccountId: this.getAdTechCompanyAccountId(),
            price: {
                final: product.price.promotional.formatted,
                regular: priceRegular,
                minimal: priceMinimal,
                isDiscounted,
                isCentsEnabled: this.isCentsEnabled,
                showOmnibus,
                showPrevious,
                showPricePrefix: isPriceMinimal,
            },
            images: product.images.map((_, index) => {
                const image = getProductImage(
                    product,
                    index,
                    [IMAGE_TYPE_PRODUCT_CARD],
                    IMAGE_TYPE_PRODUCT_CARD
                );

                image.src = image.url;
                delete image.url;

                return image;
            }),
            badges: edgeBadges.map(({ type, content: label }) => ({
                type: BADGE_EDGE_VARIANTS_MAP[type],
                label,
            })),
            promoBadge,
            sizes,
        };
    }

    getBrandName(locale) {
        return this.getStringAttributeValueLabel(BRAND_ATTRIBUTE_NAME, false, locale);
    }

    getBrandImage(locale = this.locale) {
        const imageType = IMAGE_TYPE_BRAND;
        const brandAttribute = this.values[BRAND_ATTRIBUTE_NAME];
        const producer = this.getObjectAttributeValueLabel(PRODUCER_ATTRIBUTE_NAME, false, locale);

        if (brandAttribute && producer?.image) {
            const imagePath = producer.image[locale] ? producer.image[locale] : producer.image;

            const brandUrlKey = brandAttribute.value.url;

            return this.$imaginator.getImage(imagePath, brandUrlKey, brandUrlKey, imageType);
        }

        return getPlaceholder(imageType);
    }

    getOccasion(locale) {
        return this.getArrayAttributeValueLabel(OCCASION_ATTRIBUTE_NAME, false, locale);
    }

    getOutlet() {
        return this.getValueCodeAsBoolean(OUTLET_ATTRIBUTE_NAME);
    }

    getPurpose(locale) {
        return this.getArrayAttributeValueLabel(PURPOSE_ATTRIBUTE_NAME, false, locale);
    }

    getSexName(locale) {
        return this.getStringAttributeValueLabel(SEX_NAME_ATTRIBUTE_NAME, false, locale);
    }

    getStyle(locale) {
        return this.getArrayAttributeValueLabel(STYLE_ATTRIBUTE_NAME, false, locale);
    }

    getImageSizeTable() {
        const imageValue = this.values[SIZE_TABLE_ATTRIBUTE_NAME];

        if (!imageValue) {
            return {};
        }

        const { code = '', values = {} } = imageValue.value || {};

        return {
            code,
            ...values,
        };
    }

    getBrandUrl() {
        const brandValue = this.values[BRAND_ATTRIBUTE_NAME];
        const brandAttribute = this.attributes[BRAND_WITH_COLLECTION_ATTRIBUTE_NAME];

        if (!brandValue || !brandAttribute) {
            return null;
        }

        const path = this.$stringifyCategoryUrl({
            categoryUrl: DEFAULT_SEARCH_CATEGORY,
            filters: {
                [brandAttribute.url]: [brandValue.value.url],
            },
        });

        return this.$createCategoryPath(path);
    }

    getColor(locale) {
        const mainColor = this.getStringAttributeValueLabel(COLOR_ATTRIBUTE_NAME, false, locale);

        return mainColor || this.getArrayAttributeValueLabel(COLOR_ARRAY_ATTRIBUTE_NAME)[0] || '';
    }

    getColorVariantsLink(locale) {
        return {
            value: this.getStringAttributeValueLabel(
                PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
                false,
                locale
            ),
            url: this.getStringAttributeUrl(PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME, false, locale),
        };
    }

    getColorVariantsCount() {
        return this.getStringAttributeValueLabel(
            PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
            false
        );
    }

    getFason(locale) {
        return this.getStringAttributeValueLabel(FASON_ATTRIBUTE_NAME, false, locale);
    }

    getImageAlt(locale) {
        return `${this.getBrandName(locale)} ${this.getName(locale)}`.trim();
    }

    getImages(types, limit) {
        let images = this.getArrayAttributeValueLabel(IMAGES_ATTRIBUTE_NAME);

        if (limit) {
            images = images.slice(0, limit);
        }

        const categorySex = Product.getMainCategoryUrl(DEFAULT_LOCALE, this.categories);

        if (categorySex) {
            images = images.filter(({ values: { plec } }) => {
                if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                    return true;
                }

                return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(plec.toLowerCase());
            });
        }

        return images.map(image => {
            const result = {
                family: image.family,
            };

            types.forEach(imageType => {
                let sourceImageTypes = [imageType];

                if (imageType === IMAGE_TYPE_PRODUCT_CARD) {
                    sourceImageTypes = [
                        IMAGE_TYPE_PRODUCT_CARD,
                        IMAGE_TYPE_PRODUCT_CARD_2X,
                        IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
                    ];
                }

                result[imageType] = this.$imaginator.getImage(
                    image.url,
                    this.getUrlKey(),
                    this.getImageAlt(),
                    imageType,
                    DEFAULT_IMAGE_FORMAT,
                    SOURCE_IMAGE_FORMATS,
                    sourceImageTypes
                );
            });

            return result;
        });
    }

    getModel(locale) {
        return this.getStringAttributeValueLabel(MODEL_ATTRIBUTE_NAME, false, locale);
    }

    getName(locale) {
        return this.getStringAttributeValueLabel(NAME_ATTRIBUTE_NAME, false, locale);
    }

    getNameDisplay(locale) {
        const name = this.getStringAttributeValueLabel(NAME_DISPLAY_ATTRIBUTE_NAME, false, locale);

        return name || this.getName(locale);
    }

    getSku(locale) {
        return this.getStringAttributeValueLabel(SKU_ATTRIBUTE_NAME, false, locale);
    }

    static hasPromoSticker(promoSticker = {}) {
        return Object.keys(promoSticker).length > 0;
    }

    async getPromoAction() {
        const promoActionCacheKey = 'promoAction';

        const { storeViewTimezone, locale, variants, $dateHelper } = this;

        const promoActionValue = await this.getAttributeValueLabel(
            STICKER_ATTRIBUTE_NAME,
            false,
            locale
        );

        const promoAction = await getPromoActionSticker({
            locale,
            variants,
            promoActionValue,
            storeViewTimezone,
            $dateHelper,
        });

        return this.cache.set(promoActionCacheKey, promoAction);
    }

    async getPromoSticker() {
        const promoStickerCacheKey = 'promoSticker';
        const $$promoStickerCache = this.cache.get(promoStickerCacheKey);

        if (typeof $$promoStickerCache !== 'undefined') {
            return $$promoStickerCache;
        }

        const hotDealSticker = await this.getHotDeal();

        if (hotDealSticker) {
            return this.cache.set(promoStickerCacheKey, hotDealSticker);
        }

        const actionSticker = await this.getPromoAction();

        if (actionSticker) {
            return this.cache.set(promoStickerCacheKey, actionSticker);
        }

        return this.cache.set(promoStickerCacheKey, {});
    }

    getLoyaltyClubAttribute(locale = this.locale) {
        const loyaltyClubAttribute = this.getAttributeValueLabel(
            LOYALTY_CLUB_ATTRIBUTE_NAME,
            false,
            locale
        );

        return !loyaltyClubAttribute || loyaltyClubAttribute !== LOYALTY_CLUB_PRODUCT_FALSE;
    }

    getUrl(locale) {
        return this.$createProductPath(
            this.getStringAttributeValueLabel(URL_KEY_ATTRIBUTE_NAME, false, locale)
        );
    }

    getUrlKey(locale) {
        return this.getArrayAttributeValueLabel(URL_KEY_ATTRIBUTE_NAME, false, locale);
    }

    getVideo(locale) {
        const url = this.getStringAttributeValueLabel(VIDEO_ATTRIBUTE_NAME, false, locale);

        return getVimeoVideoUrl(url) || getYoutubeVideoUrl(url);
    }

    getBadges(locale) {
        return this.getObjectAttributeValueLabel(BADGES_ATTRIBUTE_NAME, true, locale);
    }

    getBadgesVisible(locale) {
        const badges = this.getBadges(locale);

        const {
            [ONLY_HERE_ATTRIBUTE_NAME]: toRemove,
            [PREMIUM_ATTRIBUTE_NAME]: premiumAttributeToRemove,
            ...badgesRest
        } = badges;

        return badgesRest;
    }

    async getProductBadgeConfig(
        locale = this.locale,
        isGoodPriceBadgeEnabled = false,
        isOccasionBadgeEnabled = false
    ) {
        const productBadgeConfigCacheKey = `productBadgeConfig-${
            isGoodPriceBadgeEnabled ? 'goodPrice' : ''
        }`;
        const $$productBadgeConfigCache = this.cache.get(productBadgeConfigCacheKey);

        if (typeof $$productBadgeConfigCache !== 'undefined') {
            return $$productBadgeConfigCache;
        }

        const badges = [];

        const isOutlet = this.getOutlet();

        if (isOutlet && this.isOutletEnabled) {
            badges.push(
                new ProductBadge({
                    type: TYPE_OUTLET,
                    content: 'Outlet',
                }).getPlainObject()
            );
        }

        const { omnibus: { amount: omnibusAmount } = {} } = this.getPrice() || {};

        const shouldDisplayActionSticker = (!this.isOmnibusEnabled || !!omnibusAmount) && !isOutlet;

        if (shouldDisplayActionSticker) {
            const hotDealAction = await this.getHotDeal();

            const {
                badgeBackgroundColor: hotDealBackgroundColor,
                badgeTextColor: hotDealTextColor,
                formattedBadgeContent: hotDealLabel,
                isInternal: isHotDealInternal,
            } = hotDealAction || {};

            if (hotDealBackgroundColor && hotDealLabel) {
                badges.push(
                    new ProductBadge({
                        type: TYPE_HOT_DEAL,
                        backgroundColor: hotDealBackgroundColor,
                        color: hotDealTextColor,
                        content: hotDealLabel,
                        isInternal: isHotDealInternal,
                    }).getPlainObject()
                );
            }

            const promoAction = await this.getPromoAction();

            const {
                badgeBackgroundColor,
                badgeTextColor,
                formattedBadgeContent,
                isInternal: isPromoInternal,
                endDate,
            } = promoAction || {};

            if (badgeBackgroundColor && formattedBadgeContent) {
                badges.push(
                    new ProductBadge({
                        type: TYPE_PROMO_ACTION,
                        backgroundColor: badgeBackgroundColor,
                        color: badgeTextColor,
                        content: formattedBadgeContent,
                        isInternal: isPromoInternal,
                        data: {
                            endDate,
                        },
                    }).getPlainObject()
                );
            }
        }

        const { badgeType, badgeContent } = this.getDiscountSticker(
            isGoodPriceBadgeEnabled,
            isOccasionBadgeEnabled
        );

        if (badgeType) {
            badges.push(
                new ProductBadge({
                    type: badgeType,
                    content: badgeContent,
                }).getPlainObject()
            );
        }

        const { nowosc } = this.getBadgesVisible(locale);

        if (nowosc) {
            badges.push(
                new ProductBadge({
                    type: TYPE_NEW,
                    content: nowosc.label,
                }).getPlainObject()
            );
        }

        if (this.getIsEcoFriendly()) {
            badges.push(
                new ProductBadge({
                    type: TYPE_ECO_FRIENDLY,
                    content: 'eco-friendly',
                }).getPlainObject()
            );
        }

        const isOnlyHere = this.getIsOnlyHere();

        if (Object.keys(isOnlyHere).length > 0) {
            badges.push(
                new ProductBadge({
                    content: isOnlyHere.label,
                    type: TYPE_ONLY_HERE,
                }).getPlainObject()
            );
        }

        const badgesSorted = sortBadges(badges);

        return this.cache.set(productBadgeConfigCacheKey, badgesSorted);
    }

    async hasProductBadge(locale = this.locale) {
        const productBadges = (await this.getProductBadgeConfig(locale)) || [];

        return productBadges.length > 0;
    }

    getIsActive(locale) {
        return this.getBooleanAttributeValueLabel(ACTIVE_ATTRIBUTE_NAME, false, locale);
    }

    getIsOneSize() {
        const sizes = Object.values(this.variants || {});
        const hasSingleSize = sizes.length === 1;

        if (!hasSingleSize) {
            return false;
        }

        const {
            family: { variant_dimension: variantDimension = null },
        } = this;

        const [size] = sizes;

        const sizeLabel = getProductVariantLabel(size, variantDimension);
        const doubleSize = getDoubleSize(size);

        return [sizeLabel, doubleSize].includes(PRODUCT_ONE_SIZE_LABEL);
    }

    getProductPrice(attributeCode) {
        return this.getStringAttributeValueLabel(attributeCode, true, this.locale, this.currency);
    }

    getIsPriceMinimal() {
        const finalPrice = this.getProductPrice(FINAL_PRICE_ATTRIBUTE_NAME);

        return finalPrice.type === PRICE_TYPE_MINIMAL;
    }

    getPrice(locale, currency) {
        const priceCacheKey = 'price';
        const $$priceCache = this.cache.get(priceCacheKey);

        if (typeof $$priceCache !== 'undefined') {
            return $$priceCache;
        }

        const regularPrice = this.getProductPrice(PRICE_ATTRIBUTE_NAME);
        const finalPrice = this.getProductPrice(FINAL_PRICE_ATTRIBUTE_NAME);
        const omnibusPrice = this.getProductPrice(OMNIBUS_PRICE_ATTRIBUTE_NAME);

        return this.cache.set(
            priceCacheKey,
            getPrice(
                regularPrice,
                finalPrice,
                omnibusPrice,
                this.getDiscount(locale, currency),
                this.getDiscountRegular(locale, currency),
                this.getIsPriceMinimal()
            )
        );
    }

    getSeriesName(locale) {
        return this.getStringAttributeValueLabel(SERIES_NAME_ATTRIBUTE_NAME, false, locale);
    }

    getSizeType(locale) {
        return this.getStringAttributeValueLabel(SIZE_TYPE_NAME_ATTRIBUTE_NAME, false, locale);
    }

    getSizeTypeCode() {
        const siteTypeAttribute = this.values[SIZE_TYPE_NAME_ATTRIBUTE_NAME];

        const { value: { code = '' } = {} } = siteTypeAttribute || {};

        return code;
    }

    get sexName() {
        return this.getStringAttributeValueLabel(SEX_NAME_ATTRIBUTE_NAME);
    }

    get style() {
        return this.getStringAttributeValueLabel(STYLE_ATTRIBUTE_NAME);
    }

    getBooleanAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || false;
    }

    getStringAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || '';
    }

    getArrayAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || [];
    }

    getObjectAttributeValueLabel(...params) {
        return this.getAttributeValueLabel(...params) || {};
    }

    getDiscountValue(attribute, locale, currency) {
        const { amount: discount = 0 } = this.getObjectAttributeValueLabel(
            attribute,
            true,
            locale,
            currency
        );

        return parseInt(discount, 10) > 0 ? `${discount}%` : null;
    }

    getDiscountRegular(locale, currency) {
        return this.getDiscountValue(DISCOUNT_ATTRIBUTE_NAME, locale, currency);
    }

    getDiscountOmnibus(locale, currency) {
        return this.getDiscountValue(OMNIBUS_DISCOUNT_ATTRIBUTE_NAME, locale, currency);
    }

    getDiscount(locale, currency) {
        return this.$localeConfigByKey(IS_OMNIBUS_STRICT_ENABLED)
            ? this.getDiscountOmnibus(locale, currency)
            : this.getDiscountRegular(locale, currency);
    }

    getDiscountSticker(isGoodPriceBadgeEnabled, isOccasionBadgeEnabled) {
        const {
            discount = null,
            regular: { amount: regularAmount },
            promotional: { amount: promotionalAmount },
            omnibus: { amount: omnibusAmount } = {},
        } = this.getPrice() || {};

        return Product.getDiscountBadge({
            discount,
            regularAmount,
            omnibusAmount,
            promotionalAmount,
            isGoodPriceBadgeEnabled,
            isOccasionBadgeEnabled,
            isOmnibusEnabled: this.isOmnibusEnabled,
            $t: this.$t.bind(this),
        });
    }

    static getDiscountBadge({
        discount = null,
        regularAmount,
        omnibusAmount,
        promotionalAmount,
        isGoodPriceBadgeEnabled,
        isOccasionBadgeEnabled,
        isOmnibusEnabled,
        $t,
    }) {
        let badgeType = null;
        let badgeContent = null;

        const discountAmount = parseInt(discount, 10);

        if (discountAmount >= OMNIBUS_OCCASION_TRESHOLD) {
            badgeType = TYPE_DISCOUNT;
            badgeContent = `-${discountAmount}%`;
        }

        if (!isOmnibusEnabled) {
            return { badgeType, badgeContent };
        }

        if (
            isGoodPriceBadgeEnabled &&
            omnibusAmount &&
            omnibusAmount <= promotionalAmount &&
            promotionalAmount < regularAmount
        ) {
            badgeType = 'Good price';
            badgeContent = $t('Good price');
        }

        if (
            isOccasionBadgeEnabled &&
            discountAmount > 0 &&
            discountAmount < OMNIBUS_OCCASION_TRESHOLD
        ) {
            badgeType = TYPE_OCCASION;
            badgeContent = $t('Occasion');
        }

        return { badgeType, badgeContent };
    }

    getIsEcoFriendly() {
        const ecoFriendlyValue = this.getStringAttributeValueLabel(
            ECO_FRIENDLY_ATTRIBUTE_NAME,
            true
        );

        return ecoFriendlyValue?.code === IS_ECO_FRIENDLY_VALUE_CODE;
    }

    getIsOnlyHere() {
        return this.getBadges()?.[ONLY_HERE_ATTRIBUTE_NAME] || {};
    }

    getSizeSuggestion() {
        return this.getStringAttributeValueLabel(SIZE_SUGGESTION_ATTRIBUTE_NAME);
    }

    getIsPremium(locale = this.locale) {
        const producer = this.getObjectAttributeValueLabel(PRODUCER_ATTRIBUTE_NAME, false, locale);

        return !!producer?.[PREMIUM_ATTRIBUTE_NAME];
    }

    getRelatedIndexes() {
        return this.associations?.pack || [];
    }

    getStringAttributeUrl(attributeCode) {
        return this.attributes[attributeCode]?.url || '';
    }

    getValueCodeAsBoolean(attributeCode) {
        const productValue = this.values?.[attributeCode];

        return !!parseInt(productValue?.value?.code, 10);
    }

    getAttributeValueLabel(
        attributeCode,
        raw = false,
        locale = this.locale,
        currency = this.currency
    ) {
        const productValue = this.values[attributeCode];
        const productAttribute = this.attributes[attributeCode];

        if (!productValue || !productAttribute) {
            return;
        }

        const { localizable, type } = productAttribute;

        if (localizable) {
            if ([ATTRIBUTE_TYPE_TEXT, ATTRIBUTE_TYPE_BOOLEAN].includes(type)) {
                return productValue.value[locale];
            }

            if (type === ATTRIBUTE_TYPE_PRICE_COLLECTION) {
                const { value = {} } = productValue;

                const valueOfLocaleAndCurrency = value?.[locale]?.[currency];

                if (!valueOfLocaleAndCurrency) {
                    return {};
                }

                return raw ? valueOfLocaleAndCurrency : valueOfLocaleAndCurrency.formatted;
            }

            if (type === ATTRIBUTE_TYPE_REFERENCE_ENTITY) {
                const { value = {} } = productValue;

                return value[locale] || {};
            }

            if (type === ATTRIBUTE_TYPE_REFERENCE_ENTITY_COLLECTION) {
                return productValue.value[locale];
            }

            if (type === ATTRIBUTE_TYPE_MULTI_SELECT) {
                const { value = {} } = productValue;

                if (!value[locale]) {
                    return {};
                }

                const localeValue = value[locale];

                return raw ? localeValue : Object.values(localeValue).map(({ label }) => label);
            }
        } else {
            if ([ATTRIBUTE_TYPE_TEXT, ATTRIBUTE_TYPE_NUMBER].includes(type)) {
                return productValue.value;
            }

            if (type === ATTRIBUTE_TYPE_SIMPLE_SELECT) {
                return raw ? productValue.value : productValue.value.label;
            }

            if (type === ATTRIBUTE_TYPE_MULTI_SELECT) {
                if (raw) {
                    return productValue.value;
                }

                const values = productValue.value;

                return Object.keys(values).map(key => {
                    return values[key].label;
                });
            }

            if (type === ATTRIBUTE_TYPE_REFERENCE_ENTITY) {
                return productValue?.value?.values || {};
            }

            if (type === ATTRIBUTE_TYPE_ASSETS_COLLECTION) {
                return productValue.value;
            }
        }
    }

    getIsSponsored() {
        return !!Object.keys(this.sponsorshipDetails).length;
    }

    getAdTechEvents() {
        const {
            click_event_urls: onClickBeacons = [],
            view_event_urls: onViewBeacons = [],
            load_event_urls: onLoadBeacons = [],
        } = this.sponsorshipDetails;

        return {
            onClickBeacons,
            onViewBeacons,
            onLoadBeacons,
        };
    }

    getAdTechCompanyAccountId() {
        const { account_id = '' } = this.sponsorshipDetails;

        return account_id;
    }

    async getHotDeal() {
        const hotDealCacheKey = 'hotDeal';
        const $$hotDealCache = this.cache.get(hotDealCacheKey);

        if (typeof $$hotDealCache !== 'undefined') {
            return $$hotDealCache;
        }

        const { storeViewTimezone, variants, locale, $dateHelper } = this;

        const hotDealValue = this.getObjectAttributeValueLabel(HOT_DEAL_ATTRIBUTE_NAME, true);

        const hotDeal = await getHotDealSticker({
            storeViewTimezone,
            locale,
            variants,
            hotDealValue,
            $dateHelper,
        });

        return this.cache.set(hotDealCacheKey, hotDeal);
    }

    getHasOneVariant() {
        return Object.values(this.variants).length === 1;
    }

    getShortName(locale) {
        return this.getStringAttributeValueLabel(SHORT_NAME_ATTRIBUTE_NAME, false, locale);
    }

    getMainImageType() {
        return this.getImages([IMAGE_TYPE_PRODUCT], 1)[0].family;
    }

    static getMainCategoryUrl(locale, categories = []) {
        return categories[0]?.[1]?.translations?.[locale]?.url || DEFAULT_SEARCH_CATEGORY;
    }

    static getCategory(locale, categories = []) {
        if (!categories.length) {
            return null;
        }

        const firstCategories = categories[0] || [];
        const category = firstCategories[firstCategories.length - 1] || null;

        return category?.translations?.[locale] || null;
    }

    static extendProductWithCategoriesData(product, categories, locale) {
        const categorySex = Product.getMainCategoryUrl(DEFAULT_LOCALE, categories);

        const filteredImages = product.images.filter(({ values: { plec } = {} }) => {
            if (!plec || !PRODUCT_IMAGE_SEX_TYPES[categorySex]) {
                return true;
            }

            return PRODUCT_IMAGE_SEX_TYPES[categorySex].includes(plec.toLowerCase());
        });

        return {
            ...product,
            images: categorySex ? filteredImages : product.images,
            analytics: {
                ...product.analytics,
                categories,
            },
            category: Product.getCategory(locale, categories),
            mainCategoryUrl: Product.getMainCategoryUrl(locale, categories),
            categories,
        };
    }
}
