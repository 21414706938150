import { MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG } from '@configs/megatron-client';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export default (megatronClient, $errorHandler) => ({
    async availableCards(customerUuid, isMarketplace) {
        try {
            return await megatronClient.get(
                `v1/customers/${customerUuid}/payu-cards?marketplace=${isMarketplace}`,
                {
                    ...MEGATRON_CLIENT_ENDPOINT_PARAMETER_SMAUG,
                    context: {
                        useAuthorization: false,
                    },
                }
            );
        } catch (err) {
            $errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'services.payments.payu.payu-cards',
                },
                {
                    payload: {
                        customerUuid,
                        isMarketplace,
                    },
                }
            );
        }
    },
});
