export const ABTEST_COOKIE_NAME = 'abtest';

export const AB_TEST_COOKIE_VALUE_SEPARATOR = ':';
export const AB_TEST_COOKIE_VARIANT_SEPARATOR = '=';

export const AB_TEST_SEARCH_COOKIE_SUFFIX = '_SBE';

export const X_SEARCH_ABTEST_HEADER_NAME = 'x-search-abtest';

export const SEARCH_SERVICE_ENGINE_COOKIE_NAME = 'mod_pl_plp_searchServiceEngine';
export const SEARCH_SERVICE_ENGINE_COOKIE_NAME_ALL = 'mod_all_plp_searchServiceEngine_v2';
export const USE_SYNERISE_ENGINE_COOKIE_NAME = 'mod_all_plp_useSyneriseEngine';
export const COOKIE_AUTOCOMPLETE_POC = 'mod_all_plp_autocompletePoc';
export const COOKIE_AUTOCOMPLETE_POC_CATEGORIES = 'mod_all_plp_autocompletePocCategories';
export const COOKIE_AUTOCOMPLETE_POC_PHRASES = 'mod_all_plp_autocompletePocPhrases';
export const COOKIE_SEARCH_BY_CATEGORY = 'mod_all_plp_searchByCategory';
export const USE_SEARCH_SORTING_VARIANTS = 'mod_all_plp_sorting_variants_2';
export const USE_TOP_BANNER_ON_LISTING = 'mod_all_plp_listingTopBanner';
export const SEARCH_SERVICE_VISUAL_SEARCH = 'mod_all_plp_visual_search';
export const SEARCH_SERVICE_DEPLOYMENT = 'mod_all_plp_deployment';
export const VISUAL_SEARCH_BY_IMG = 'mod_all_plp_visual_search_input';
export const VISUAL_SEARCH_BY_IMG_CAMERA_BTN = 'mod_all_plp_visual_search_input_camera_btn';
export const SEARCH_SERVICE_ACTION_LISTINGS = 'mod_pl_plp_browse_actions';
